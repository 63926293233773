import {
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

const ViewProtestSummary = ({ state }) => {
  return (
    <form style={{ margin: "10%" }}>
      <Heading as="h4" size="lg" mb="2%">
        View Protest #{state.protest_id}
      </Heading>
      <FormControl>
        <FormLabel htmlFor="text">Description</FormLabel>
        <Textarea
          id="text"
          placeholder="Enter a description of what happened.."
          value={state.protest_text}
          readOnly={true}
          opacity={0.65}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="explanation">Summary</FormLabel>
        <Textarea
          id="explanation"
          readOnly={true}
          opacity={0.65}
          value={state.protest_explanation}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="verdict">Verdict</FormLabel>
        <Input
          id="verdict"
          type="text"
          value={state.protest_verdict}
          readOnly={true}
          opacity={0.65}
        />
      </FormControl>
    </form>
  );
};

export default ViewProtestSummary;
