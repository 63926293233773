import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import { displayUsers } from "../../utility/utility";

const JudgedProtestsTable = ({ protests }) => {
  const navigation = useNavigate();

  const extractDisplayText = (text) => {
    if (text.length > 22) {
      return text.substr(0, 20) + "..";
    }
    return text;
  };

  const handleRowClick = (protest) => {
    console.log("Clicked protest", protest);
    navigation("/protests/view", { state: protest });
  };

  return (
    <>
      {protests.length > 0 ? (
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Protester</Th>
              <Th>Race</Th>
              <Th>Explanation</Th>
              <Th>State</Th>
              <Th>Reported Driver</Th>
              <Th>Verdict</Th>
            </Tr>
          </Thead>
          <Tbody>
            {protests.map((protest, index) => {
              return (
                <Tr
                  key={protest.protest_id}
                  onClick={() => handleRowClick(protest)}
                >
                  <Td>{protest.protest_id}</Td>
                  <Td>{protest.protester}</Td>
                  <Td>{protest.race}</Td>
                  <Td>{extractDisplayText(protest.protest_explanation)}</Td>
                  <Td>{protest.protest_state}</Td>
                  <Td>{displayUsers(protest.reported)}</Td>
                  <Td>{extractDisplayText(protest.protest_verdict)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <p>No protests available</p>
      )}
    </>
  );
};

export default JudgedProtestsTable;
