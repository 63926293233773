import {
  Heading,
  FormControl,
  FormLabel,
  Divider,
  Textarea,
  Button,
  Flex,
  Spacer,
  ButtonGroup,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import protestservice from "../../services/protestservice";
import { displayUser } from "../../utility/utility";
import { AuthContext } from "../auth/AuthProvider";
import ViewProtestForm from "./ViewProtestForm";

const findAvailableProtestStates = (protestState) => {
  switch (protestState) {
    case "Created":
      return ["FeePaid", "Processing", "Judged", "Rejected"];
    case "FeePaid":
      return ["Processing", "Judged", "Rejected"];
    case "Processing":
      return ["Judged", "Rejected"];
    case "DriverReplied":
      return ["Judged", "Rejected"];
    default:
      return [];
  }
};

const ViewProtestAdmin = ({ state }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [verdict, setVerdict] = useState(state.protest_verdict);
  const [protestState, setProtestState] = useState(state.protest_state);
  const [explanation, setExplanation] = useState(state.protest_explanation);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [availableProtestStates, setAvailableProtestStates] = useState([]);
  const [feePaid, setFeePaid] = useState(state.fee_paid);

  useEffect(() => {
    setAvailableProtestStates(findAvailableProtestStates(state.protest_state));
    protestservice
      .getComments(state.protest_id)
      .then((response) => {
        console.log("Fetched comments", response.data);
        let temp = response.data;
        if (temp === null || temp === undefined) {
          temp = [];
        }
        setComments(temp);
      })
      .catch((err) => console.log(err));
  }, [state]);

  const findReportedEmails = () => {
    const emails = [];
    state.reported.forEach((reported) => {
      emails.push(reported.reported_email);
    });

    return emails;
  };

  const handleSubmitChanges = (e) => {
    e.preventDefault();
    const submitObject = {
      verdict: verdict,
      explanation: explanation,
      protestId: state.protest_id,
    };
    console.log("Submitting admin changes", submitObject);
    protestservice
      .submitAdminChangesToProtest(submitObject)
      .then((data) => {
        console.log(data);
        navigate("/protests");
      })
      .catch((err) => console.log(err));
  };

  const handleAddComment = (e) => {
    e.preventDefault();
    const submitObject = {
      comment: newComment,
      protestId: state.protest_id,
      userId: auth.token.id,
    };
    console.log("Adding comment", submitObject);
    protestservice
      .addCommentToProtest(submitObject)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const handleProtestStateChange = (newState) => {
    setProtestState(newState);
    let shouldSendEmails = false;
    if (newState === "Processing" && state.protest_state !== "Processing") {
      shouldSendEmails = true;
    }
    const submitObject = {
      state: newState,
      protestId: state.protest_id,
      sendEmails: shouldSendEmails,
      reportedEmails: findReportedEmails(),
    };
    console.log("Submitting protest state changes", submitObject);
    protestservice
      .submitProtestStateChanges(submitObject)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const handleFeePaidChange = (newFeePaid) => {
    setFeePaid(newFeePaid);
    const submitObject = {
      feePaid: newFeePaid,
      protestId: state.protest_id,
    };
    console.log("Submitting fee paid changes", submitObject);
    protestservice
      .submitFeePaidChanges(submitObject)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteProtest = (e) => {
    e.preventDefault();
    console.log("Deleting protest with id", state.protest_id);
    protestservice
      .deleteProtest(state.protest_id)
      .then((data) => {
        console.log(data);
        navigate("/protests");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form style={{ margin: "10%" }}>
      <Flex>
        <Heading as="h4" size="lg" mb="2%">
          View Protest #{state.protest_id}
        </Heading>
        <Spacer />
        <Button colorScheme="red" onClick={(e) => handleDeleteProtest(e)}>
          Delete Protest
        </Button>
      </Flex>
      <ViewProtestForm state={state} />
      {state.reported.map((reported, index) => {
        return (
          <FormControl key={index}>
            <FormLabel htmlFor="reply">
              Reply from{" "}
              {displayUser(reported.reported, reported.reported_driver_number)}{" "}
              {reported.reported_reply_time !== "0000-00-00 00:00:00" &&
                "at " + reported.reported_reply_time}
            </FormLabel>
            <Textarea
              id={reported.reported}
              placeholder="Driver reply to the protest.."
              readOnly={true}
              opacity={0.65}
              value={reported.reported_reply}
            />
          </FormControl>
        );
      })}
      <FormControl>
        <FormLabel htmlFor="verdict">Verdict</FormLabel>
        <Textarea
          id="verdict"
          placeholder="Enter your verdict to the protest.."
          value={verdict}
          onChange={(e) => setVerdict(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="explanation">Explanation</FormLabel>
        <Textarea
          id="explanation"
          placeholder="Enter your explanation to the protest.."
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
        />
      </FormControl>
      <Button
        colorScheme="teal"
        mt="2%"
        onClick={(e) => handleSubmitChanges(e)}
      >
        Submit changes
      </Button>
      <Flex mt="3%">
        <FormLabel>Current State: {state.protest_state}</FormLabel>
        <FormLabel ml="10%">New State: {protestState}</FormLabel>
      </Flex>
      <Flex mt="8px">
        <FormLabel>
          Current Fee: {state.fee_paid === 0 ? "Fee not paid" : "Fee paid"}
        </FormLabel>
        <FormLabel ml="12%">
          New Fee: {feePaid === 0 ? "Fee not paid" : "Fee paid"}
        </FormLabel>
      </Flex>
      <ButtonGroup spacing="12" w="100%" mt="2%">
        <Button
          colorScheme="yellow"
          disabled={!availableProtestStates.includes("Created")}
          onClick={() => handleProtestStateChange("Created")}
        >
          Created
        </Button>
        <Button
          colorScheme="yellow"
          disabled={!availableProtestStates.includes("Processing")}
          onClick={() => handleProtestStateChange("Processing")}
        >
          Processing
        </Button>
        <Button
          colorScheme="yellow"
          disabled={!availableProtestStates.includes("DriverReplied")}
          onClick={() => handleProtestStateChange("DriverReplied")}
        >
          DriverReplied
        </Button>
        <Button
          colorScheme="yellow"
          disabled={!availableProtestStates.includes("Judged")}
          onClick={() => handleProtestStateChange("Judged")}
        >
          Judged
        </Button>
        <Button
          colorScheme="yellow"
          disabled={!availableProtestStates.includes("Rejected")}
          onClick={() => handleProtestStateChange("Rejected")}
        >
          Rejected
        </Button>
        <Button
          colorScheme="blue"
          ml="50px"
          onClick={() => handleFeePaidChange((feePaid + 1) % 2)}
        >
          {feePaid === 0 ? "Set Fee Paid" : "Set Fee Paid Back"}
        </Button>
      </ButtonGroup>
      <Divider mt="3%" mb="2%" />
      {comments.length > 0 ? (
        <Heading as="h6" size="md" mb="2%">
          Comments:
        </Heading>
      ) : (
        <p>
          <i>No comments created yet.</i>
        </p>
      )}
      {comments.map((comment) => {
        return (
          <FormControl key={comment.comment_id}>
            <FormLabel>{comment.user_name}</FormLabel>
            <Textarea
              value={comment.comment_text}
              readOnly={true}
              opacity={0.65}
            />
          </FormControl>
        );
      })}
      <Divider mt="3%" mb="2%" />
      <FormControl mt="2%">
        <FormLabel htmlFor="newComment">New Comment</FormLabel>
        <Textarea
          id="newComment"
          placeholder="Enter your new comment to the protest.."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </FormControl>
      <Button colorScheme="blue" mt="2%" onClick={(e) => handleAddComment(e)}>
        Add Comment
      </Button>
    </form>
  );
};

export default ViewProtestAdmin;
