import http from "./http-common";

class ProtestWebClientService {
  getUrlResource = "fetchProtests.php";
  postUrlResource = "addProtest.php";
  getUsersAndRacesUrlResource = "fetchUsersAndRaces.php";
  getRacesUrlResource = "fetchRaces.php";
  getCommentsUrlResource = "fetchComments.php";
  addReplyUrlResource = "addReplyToProtest.php";
  submitAdminChangesUrlResource = "submitAdminChangesToProtest.php";
  submitProtestStateChangesUrlResource =
    "submitProtestStateChangesUrlResource.php";
  submitFeePaidChangesUrlResource = "submitFeePaidChangesUrlResource.php";
  addCommentUrlResource = "addComment.php";
  deleteUrlResource = "deleteProtest.php";

  getProtestsForUser(id, role, teamId) {
    return http.get(
      `${this.getUrlResource}?userId=${id}&userRole=${role}&teamId=${teamId}`
    );
  }

  getUsersAndRaces() {
    return http.get(`${this.getUsersAndRacesUrlResource}`);
  }

  getRaces() {
    return http.get(`${this.getRacesUrlResource}`);
  }

  getComments(protestId) {
    return http.get(`${this.getCommentsUrlResource}?protestId=${protestId}`);
  }

  createProtest(protest) {
    return http.post(`${this.postUrlResource}`, protest);
  }

  addReplyToProtest(reply) {
    return http.post(`${this.addReplyUrlResource}`, reply);
  }

  submitAdminChangesToProtest(changes) {
    return http.post(`${this.submitAdminChangesUrlResource}`, changes);
  }

  submitProtestStateChanges(changes) {
    return http.post(`${this.submitProtestStateChangesUrlResource}`, changes);
  }

  submitFeePaidChanges(changes) {
    return http.post(`${this.submitFeePaidChangesUrlResource}`, changes);
  }

  addCommentToProtest(comment) {
    return http.post(`${this.addCommentUrlResource}`, comment);
  }

  deleteProtest(protestId) {
    return http.get(`${this.deleteUrlResource}?protestId=${protestId}`);
  }
}

export default new ProtestWebClientService();
