module.exports.displayUser = (userName, userNumber) => {
  if (userNumber !== null && userNumber !== undefined) {
    return `${userName} (#${userNumber})`;
  }

  return userName;
};

module.exports.displayUsers = (reportedUsers) => {
  let finalString = "";
  const length = reportedUsers.length;
  reportedUsers.forEach((user, index) => {
    if (
      user.reported_driver_number !== null &&
      user.reported_driver_number !== undefined
    ) {
      finalString += `${user.reported} (#${user.reported_driver_number})`;
    } else {
      finalString += user.reported;
    }
    if (index < length - 1) {
      finalString += "; ";
    }
  });
  return finalString;
};
