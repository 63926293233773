import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/auth/AuthProvider";
import RequireAuth from "./components/auth/RequireAuth";
import LoginComponent from "./components/auth/LoginComponent";
import AddProtest from "./components/protests/AddProtest";
import ProtestsList from "./components/protests/ProtestsList";
import Header from "./components/Header";
import ViewProtest from "./components/protests/ViewProtest";

function App() {
  return (
    <AuthProvider>
      <Header />
      <Routes>
        <Route index element={<LoginComponent />} />
        <Route
          path="protests"
          element={
            <RequireAuth>
              <ProtestsList />
            </RequireAuth>
          }
        />
        <Route
          path="protests/create"
          element={
            <RequireAuth>
              <AddProtest />
            </RequireAuth>
          }
        />
        <Route
          path="protests/view"
          element={
            <RequireAuth>
              <ViewProtest />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
