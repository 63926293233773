import {
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { displayUser } from "../../utility/utility";
import { AuthContext } from "../auth/AuthProvider";
import ViewProtestForm from "./ViewProtestForm";

const ViewProtestFormReply = ({ state, addReplyCb }) => {
  const auth = useContext(AuthContext);
  const [reply, setReply] = useState("");
  const [isReplyDisabled, setIsReplyDisabled] = useState(true);
  const [shouldChangeToDriverReplied, setShouldChangeToDriverReplied] =
    useState(false);

  useEffect(() => {
    let isOtherDriverNotReplied = false;
    state.reported.forEach((reported) => {
      if (
        reported.reported_id !== auth.token.id &&
        reported.reported_reply === ""
      ) {
        isOtherDriverNotReplied = true;
      }
    });
    setShouldChangeToDriverReplied(!isOtherDriverNotReplied);
  }, [state.reported, auth.token]);

  useEffect(() => {
    let reportedReply = "";
    state.reported.forEach((reported) => {
      if (reported.reported_id === auth.token.id) {
        reportedReply = reported.reported_reply;
      }
    });
    console.log("Returning reply", reportedReply);
    setReply(reportedReply);
  }, [state.reported, auth.token]);

  useEffect(() => {
    if (state.protest_verdict !== "") {
      return true;
    }

    let isDisabled = true;
    state.reported.forEach((reported) => {
      if (
        reported.reported_id === auth.token.id &&
        reported.reported_reply === ""
      ) {
        isDisabled = false;
      }
    });

    setIsReplyDisabled(isDisabled);
  }, [state, auth.token]);

  const handleSubmitReply = (e) => {
    e.preventDefault();
    addReplyCb(reply, shouldChangeToDriverReplied);
  };

  return (
    <form style={{ margin: "10%" }}>
      <Heading as="h4" size="lg" mb="2%">
        View Protest #{state.protest_id}
      </Heading>
      <ViewProtestForm state={state} />
      {state.reported.map((reported, index) => {
        return (
          reported.reported_id === auth.token.id && (
            <FormControl key={index}>
              <FormLabel htmlFor="reply">
                Reply from{" "}
                {displayUser(
                  reported.reported,
                  reported.reported_driver_number
                )}{" "}
                {reported.reported_reply_time !== "0000-00-00 00:00:00" &&
                  "at " + reported.reported_reply_time}
              </FormLabel>
              <Textarea
                id={reported.reported}
                placeholder="Enter reply to the protest.."
                readOnly={isReplyDisabled}
                opacity={isReplyDisabled ? 0.65 : 1}
                value={reply}
                onChange={(e) => setReply(e.target.value)}
              />
            </FormControl>
          )
        );
      })}

      {isReplyDisabled ? (
        <FormControl>
          <FormLabel htmlFor="verdict">Verdict</FormLabel>
          <Input
            id="verdict"
            type="text"
            value={state.protest_verdict}
            readOnly={true}
            opacity={0.65}
          />
        </FormControl>
      ) : (
        <Button
          colorScheme="teal"
          mt="2%"
          onClick={(e) => handleSubmitReply(e)}
        >
          Reply protest
        </Button>
      )}
    </form>
  );
};

export default ViewProtestFormReply;
