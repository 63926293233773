import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "./AuthProvider";

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowValidationError, setIsShowValidationError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    console.log("login here with", auth.token);
    if (auth.token !== null) {
      navigate("/protests");
    }
  }, [auth, navigate]);

  const handleOptionKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const from = location.state?.from?.pathname || "/protests";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsShowValidationError(false);
    console.log("Submitting form with", email, password);
    auth.signin({ email, password }, (token) => {
      if (token === null) {
        setEmail("");
        setPassword("");
        setIsShowValidationError(true);
      } else {
        navigate(from, { replace: true });
      }
    });
  };

  return (
    <Flex minH={"100%"} align={"center"} justify={"center"} mt={"10%"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            {isShowValidationError && (
              <FormLabel color={"red"}>
                Invalid credentials, please try again!
              </FormLabel>
            )}
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleOptionKeyPress}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"teal.400"}
                color={"white"}
                onClick={(e) => handleSubmit(e)}
                _hover={{
                  bg: "teal.500",
                }}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginComponent;
