import { Heading, Button, Image, Flex } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./auth/AuthProvider";
import peccdk_logo from "../images/peccdk_logo.jpg";
import { useNavigate } from "react-router";

const Header = () => {
  const auth = useContext(AuthContext);
  const [, setShouldRerender] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionStorageToken = sessionStorage.getItem("ProtestSystemToken");
    console.log("HHH", auth.token);
    if (sessionStorageToken !== null && auth.token === null) {
      const decodedToken = Buffer.from(
        sessionStorageToken,
        "base64"
      ).toString();
      auth.token = JSON.parse(decodedToken);
      console.log("Restored token header", auth.token);
    }
    setShouldRerender(true);
  }, [auth]);

  const handleSignOut = () => {
    auth.token = null;
    auth.signout(() => {
      navigate("/", { replace: true });
    });
  };

  const handleLogoClick = () => {
    navigate("/protests");
  };

  return (
    <Flex align={"center"} justify={"space-evenly"}>
      <Image
        htmlHeight="100px"
        htmlWidth="400px"
        objectFit="cover"
        src={peccdk_logo}
        alt="PECCDK Logo"
        onClick={() => handleLogoClick()}
      />
      <Heading as="h3" size="lg">
        {auth.token !== null
          ? "Welcome " + auth.token.name
          : "PECCDK - Protest System"}
      </Heading>
      {auth.token !== null && (
        <Button colorScheme="teal" size="sm" onClick={handleSignOut}>
          Sign-out
        </Button>
      )}
    </Flex>
    // </div>
  );
};

export default Header;
