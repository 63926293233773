import {
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import protestservice from "../../services/protestservice";
import { AuthContext } from "../auth/AuthProvider";
import JudgedProtestsTable from "./JudgedProtestsTable";
import ProtestsTable from "./ProtestsTable";
import "../../css/styles.css";

const adminUserRoles = ["Admin", "Steward"];
const reportedValidViewStates = ["Processing", "DriverReplied", "Judged"];
const ProtestsList = () => {
  const auth = useContext(AuthContext);
  const [protests, setProtests] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    if (
      auth.token.role === "CrewChief" &&
      (auth.token.team_id === undefined ||
        auth.token.team_id.length === 0 ||
        auth.token.team_id[0] === undefined ||
        auth.token.team_id[0] === null)
    ) {
      return;
    }
    protestservice
      .getProtestsForUser(auth.token.id, auth.token.role, auth.token.team_id)
      .then((response) => {
        console.log("Fetched protests", response.data);
        let temp = [...response.data];
        if (temp === null || temp === undefined) {
          temp = [];
        }
        const map = new Map();
        temp.forEach((protest) => {
          convertValuesToArrays(protest);
          if (map.has(protest.protest_id)) {
            const tempProtest = map.get(protest.protest_id);

            if (
              !tempProtest.protester_team_id.includes(
                protest.protester_team_id[0]
              )
            ) {
              tempProtest.protester_team_id.push(protest.protester_team_id[0]);
            }

            let includedReported = undefined;
            tempProtest.reported.forEach((reported) => {
              // console.log("Checking", tempProtest.protest_id, )
              if (reported.reported_id === protest.reported[0].reported_id) {
                includedReported = reported;
              }
            });

            if (includedReported === undefined) {
              tempProtest.reported.push(protest.reported[0]);
            } else {
              if (
                !includedReported.reported_team_id.includes(
                  protest.reported[0].reported_team_id[0]
                )
              ) {
                includedReported.reported_team_id.push(
                  protest.reported[0].reported_team_id[0]
                );
              }
            }
          } else {
            map.set(protest.protest_id, protest);
          }
        });

        const finalList = [...map.values()];
        console.log("Final list", finalList);
        setProtests(finalList);
      })
      .catch((err) => console.log(err));
  }, [auth.token]);

  const convertValuesToArrays = (protest) => {
    let tempValue = protest.protester_team_id;
    let tempArray = [tempValue];
    protest.protester_team_id = [...tempArray];

    tempValue = {
      reported: protest.reported,
      reported_driver_number: protest.reported_driver_number,
      reported_id: protest.reported_id,
      reported_email: protest.reported_email,
      reported_team_id: [protest.reported_team_id],
      reported_reply: protest.protest_reply,
      reported_reply_time: protest.protest_reply_time,
    };

    tempArray = [tempValue];
    protest.reported = tempArray;

    protest.reported_driver_number = undefined;
    protest.reported_id = undefined;
    protest.reported_email = undefined;
    protest.reported_team_id = undefined;
    protest.protest_reply = undefined;
    protest.protest_reply_time = undefined;
  };

  const filterBasedOnReportedName = () => {
    return protests.filter(
      (p) =>
        getAllReportedDriverNames(p).includes(auth.token.name) &&
        reportedValidViewStates.includes(p.protest_state)
    );
  };

  const getAllReportedDriverNames = (protest) => {
    const names = [];
    protest.reported.forEach((r) => {
      names.push(r.reported);
    });

    return names;
  };

  const filterBasedOnReportedTeamId = () => {
    return protests.filter((p) => {
      if (!reportedValidViewStates.includes(p.protest_state)) {
        return false;
      }

      const reportedTeamIds = getAllReportedDriverTeamIds(p);
      let isIncluded = false;
      auth.token.team_id.forEach((tokenTeamId) => {
        if (reportedTeamIds.includes(tokenTeamId)) {
          isIncluded = true;
        }
      });

      return isIncluded;
    });
  };

  const getAllReportedDriverTeamIds = (protest) => {
    const ids = [];
    protest.reported.forEach((r) => {
      ids.push(...r.reported_team_id);
    });

    return ids;
  };

  const handleNavigateToCreateProtest = () => {
    navigation("/protests/create");
  };

  const findTabList = () => {
    if (auth.token.role === "Driver") {
      return ["Your protests", "Protests against you", "Judged protests"];
    }
    if (auth.token.role === "CrewChief") {
      return [
        "Your team's protests",
        "Protests against your team",
        "Judged protests",
      ];
    }
    if (adminUserRoles.includes(auth.token.role)) {
      return ["All protests"];
    }

    return [];
  };

  const findTabPanel = () => {
    if (auth.token.role === "Driver") {
      return (
        <TabPanels>
          <TabPanel>
            <ProtestsTable
              protests={protests.filter((p) => p.protester === auth.token.name)}
            />
          </TabPanel>
          <TabPanel>
            <ProtestsTable protests={filterBasedOnReportedName()} />
          </TabPanel>
          <TabPanel>
            <JudgedProtestsTable
              protests={protests.filter((p) => p.protest_state === "Judged")}
            />
          </TabPanel>
        </TabPanels>
      );
    }
    if (auth.token.role === "CrewChief") {
      return (
        <TabPanels>
          <TabPanel>
            <ProtestsTable
              protests={protests.filter((p) => {
                let isIncluded = false;
                auth.token.team_id.forEach((tokenTeamId) => {
                  if (p.protester_team_id.includes(tokenTeamId)) {
                    isIncluded = true;
                  }
                });

                return isIncluded;
              })}
            />
          </TabPanel>
          <TabPanel>
            <ProtestsTable protests={filterBasedOnReportedTeamId()} />
          </TabPanel>
          <TabPanel>
            <JudgedProtestsTable
              protests={protests.filter((p) => p.protest_state === "Judged")}
            />
          </TabPanel>
        </TabPanels>
      );
    }
    if (adminUserRoles.includes(auth.token.role)) {
      return (
        <TabPanels>
          <TabPanel>
            <ProtestsTable protests={protests} />
          </TabPanel>
        </TabPanels>
      );
    }
  };

  return (
    <div>
      <Button
        m="5% 7% 2% 7%"
        colorScheme="teal"
        size="lg"
        onClick={() => handleNavigateToCreateProtest()}
      >
        Create Protest
      </Button>
      <Tabs isFitted m="2% 7% 2% 7%">
        <TabList mb="1em">
          {findTabList().map((tab, index) => {
            return <Tab key={index}>{tab}</Tab>;
          })}
        </TabList>
        {findTabPanel()}
      </Tabs>
    </div>
  );
};

export default ProtestsList;
