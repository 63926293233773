import React, { useState } from "react";
import userservice from "../../services/userservice";

let AuthContext = React.createContext({});
const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const signin = (user, callback) => {
    userservice
      .login(user)
      .then((response) => {
        const token = response.data;
        console.log("Token", token);
        const convertedToken = convertReceivedDataToToken(token);
        console.log("Converted token", convertedToken);
        setToken(convertedToken);
        sessionStorage.setItem(
          "ProtestSystemToken",
          Buffer.from(JSON.stringify(convertedToken)).toString("base64")
        );
        callback(convertedToken);
      })
      .catch((err) => {
        console.log("Error when logging in", err);
      });
  };

  const convertReceivedDataToToken = (data) => {
    if (data === null || data === undefined || data.length === 0) {
      return null;
    }

    const newToken = {
      id: data[0].id,
      name: data[0].name,
      role: data[0].role,
      team_id: [],
    };

    data.forEach((element) => newToken.team_id.push(element.team_id));
    console.log("New Token", newToken);
    return newToken;
  };

  const signout = (callback) => {
    console.log("Signing out user");
    setToken(null);
    sessionStorage.removeItem("ProtestSystemToken");
    callback();
  };

  const value = { token, signin, signout };
  console.log("Initialized auth value", value);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
