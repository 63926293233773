import {
  Heading,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import protestservice from "../../services/protestservice";
import { AuthContext } from "../auth/AuthProvider";
import "../../css/styles.css";
import { displayUser } from "../../utility/utility";

const formFields = ["reportedId", "raceId", "session", "text", "time"];
const AddProtest = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [protest, setProtest] = useState({
    protesterId: auth.token.id,
    reported: [],
  });
  const [users, setUsers] = useState([]);
  const [races, setRaces] = useState([]);

  useEffect(() => {
    protestservice
      .getUsersAndRaces()
      .then((response) => {
        setUsers(JSON.parse(response.data[0]));
        setRaces(JSON.parse(response.data[1]));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === formFields[4]) {
      value = convertTimeToSeconds(e.target.value);
    }
    setProtest({ ...protest, [name]: value });
  };

  const handleAnchorClick = (e) => {
    let checkList = document.getElementById("list1");
    if (checkList.classList.contains("visible")) {
      checkList.classList.remove("visible");
    } else {
      checkList.classList.add("visible");
    }
  };

  const handleDriverCheckboxSelect = (e) => {
    const driverId = e.target.value;
    let reported = [...protest.reported];
    if (reported.includes(driverId)) {
      reported = reported.filter((el) => el !== driverId);
    } else {
      reported.push(driverId);
    }

    console.log("Reported", reported);
    protest.reported = reported;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    protest.create_time = new Date().toJSON().slice(0, 19).replace("T", " ");
    console.log("Submitting protest", protest);
    protestservice
      .createProtest(protest)
      .then((data) => {
        console.log(data);
        navigate("/protests");
      })
      .catch((err) => console.log(err));
  };

  const convertTimeToSeconds = (time) => {
    const fourDigitPattern = new RegExp("^[0-5][0-9]:[0-5][0-9]$");
    if (fourDigitPattern.test(time)) {
      const minutes = time.substring(0, 2);
      const seconds = time.substring(3);
      const conversion = +minutes * 60 + +seconds;
      console.log("Conversion", conversion);
      return conversion;
    }

    const threeDigitPattern = new RegExp("^[0-9]:[0-5][0-9]$");
    if (threeDigitPattern.test(time)) {
      const minutes = time.substring(0, 1);
      const seconds = time.substring(2);
      const conversion = +minutes * 60 + +seconds;
      console.log("Conversion", conversion);
      return conversion;
    }
  };

  return (
    <form style={{ margin: "10%" }}>
      <Heading as="h4" size="lg" mb="2%">
        Create Protest
      </Heading>
      {/* <FormControl isRequired>
        <FormLabel htmlFor="driver">Reported driver</FormLabel>
        <Select
          id="driver"
          name={formFields[0]}
          onChange={(e) => handleInputChange(e)}
        >
          <option value="default" disabled={true}>
            Select the driver you want to report
          </option>
          {users.map((user) => {
            return (
              <option key={user.user_id} value={user.user_id}>
                {user.user_name}
              </option>
            );
          })}
        </Select>
      </FormControl> */}
      <FormControl isRequired>
        <FormLabel htmlFor="race">Race</FormLabel>
        <Select
          id="race"
          defaultValue={"default"}
          name={formFields[1]}
          onChange={(e) => handleInputChange(e)}
        >
          <option value="default" disabled={true}>
            Select race
          </option>
          {races.map((race) => {
            return (
              <option key={race.race_id} value={race.race_id}>
                {race.race_name}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="session">Session</FormLabel>
        <Select
          id="session"
          defaultValue={"default"}
          name={formFields[2]}
          onChange={(e) => handleInputChange(e)}
          style={{ zIndex: "1" }}
        >
          <option value="default" disabled={true}>
            Select session
          </option>
          <option>Practice</option>
          <option>Qualify</option>
          <option>Race 1</option>
          <option>WarmUp</option>
          <option>Race 2</option>
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="text">Description</FormLabel>
        <Textarea
          name={formFields[3]}
          placeholder="Enter a description of what happened.."
          onChange={(e) => handleInputChange(e)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="time">Incident time</FormLabel>
        <Input
          id="time"
          name={formFields[4]}
          type="text"
          placeholder="MM:SS"
          onChange={(e) => handleInputChange(e)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="driver">Reported driver</FormLabel>
        <div id="list1" className="dropdown-check-list">
          <span className="anchor" onClick={(e) => handleAnchorClick(e)}>
            Select driver
          </span>
          <ul className="items">
            {users.map((user, index) => {
              return (
                <li key={index}>
                  <input
                    type="checkbox"
                    value={user.user_id}
                    onChange={(e) => handleDriverCheckboxSelect(e)}
                    disabled={user.user_id === auth.token.id}
                  />
                  {displayUser(user.user_name, user.user_number)}
                </li>
              );
            })}
          </ul>
        </div>
      </FormControl>

      <Button colorScheme="teal" mt="2%" onClick={(e) => handleFormSubmit(e)}>
        Create Protest
      </Button>
    </form>
  );
};

export default AddProtest;
