import axios from "axios";

export default axios.create({
  // deployment url: "../../protest-system-php/"
  // localhost url: "http://localhost:8080/protest-system-php/"
  baseURL: "../../protest-system-php/",
  headers: {
    "Content-type": "application/json",
  },
});
