import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import protestservice from "../../services/protestservice";
import { AuthContext } from "../auth/AuthProvider";
import ViewProtestAdmin from "./ViewProtestAdmin";
import ViewProtestFormReply from "./ViewProtestFormReply";
import ViewProtestSummary from "./ViewProtestSummary";

// const regularUserRoles = ["Driver", "CrewChief"];
const adminUserRoles = ["Admin", "Steward"];
const ViewProtest = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleAddReply = (reply, shouldChangeToDriverReplied) => {
    const reply_object = {
      reply_text: reply,
      reply_date: new Date().toJSON().slice(0, 19).replace("T", " "),
      protest_id: state.protest_id,
      user_id: auth.token.id,
      driver_replied: shouldChangeToDriverReplied,
    };
    console.log("Submitting reply", reply_object);

    protestservice
      .addReplyToProtest(reply_object)
      .then((data) => {
        console.log(data);
        navigate("/protests");
      })
      .catch((err) => console.log(err));
  };

  const handleViewProtest = () => {
    if ("Driver" === auth.token.role) {
      if (isDriverIncludedInProtest()) {
        return (
          <ViewProtestFormReply state={state} addReplyCb={handleAddReply} />
        );
      } else {
        return <ViewProtestSummary state={state} />;
      }
    } else if ("CrewChief" === auth.token.role) {
      if (isTeamIncludedInProtest()) {
        return (
          <ViewProtestFormReply state={state} addReplyCb={handleAddReply} />
        );
      } else {
        return <ViewProtestSummary state={state} />;
      }
    } else if (adminUserRoles.includes(auth.token.role)) {
      return <ViewProtestAdmin state={state} />;
    }
  };

  const isDriverIncludedInProtest = () => {
    if (state.protester_id === auth.token.id) {
      return true;
    }

    let isFound = false;
    state.reported.forEach((reported) => {
      if (reported.reported_id === auth.token.id) {
        isFound = true;
      }
    });

    return isFound;
  };

  const isTeamIncludedInProtest = () => {
    let isIncluded = false;
    auth.token.team_id.forEach((teamId) => {
      if (state.protester_team_id.includes(teamId)) {
        isIncluded = true;
      }

      state.reported.forEach((reported) => {
        if (reported.reported_team_id.includes(teamId)) {
          isIncluded = true;
        }
      });
    });

    return isIncluded;
  };

  return handleViewProtest();
};

export default ViewProtest;
