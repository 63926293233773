import { FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import React from "react";
import { displayUsers } from "../../utility/utility";

const ViewProtestForm = ({ state }) => {
  return (
    <>
      <FormControl>
        <FormLabel htmlFor="race">Race</FormLabel>
        <Input
          id="race"
          type="text"
          value={state.race}
          readOnly={true}
          opacity={0.65}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="session">Session</FormLabel>
        <Input
          id="session"
          type="text"
          value={state.protest_session}
          readOnly={true}
          opacity={0.65}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="createdTime">Created Time</FormLabel>
        <Input
          id="createdTime"
          type="text"
          readOnly={true}
          opacity={0.65}
          value={state.protest_created_time}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="text">Description</FormLabel>
        <Textarea
          id="text"
          placeholder="Enter a description of what happened.."
          value={state.protest_text}
          readOnly={true}
          opacity={0.65}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="time">Incident time</FormLabel>
        <Input
          id="time"
          type="text"
          readOnly={true}
          opacity={0.65}
          value={new Date(state.protest_time * 1000)
            .toISOString()
            .substr(14, 5)}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="driver">Reported driver(s)</FormLabel>
        <Input
          id="time"
          type="text"
          value={displayUsers(state.reported)}
          readOnly={true}
          opacity={0.65}
        />
      </FormControl>
    </>
  );
};

export default ViewProtestForm;
