import React, { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const RequireAuth = ({ children }) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const sessionStorageToken = sessionStorage.getItem("ProtestSystemToken");

  if (sessionStorageToken !== null && auth.token === null) {
    const decodedToken = Buffer.from(sessionStorageToken, "base64").toString();
    auth.token = JSON.parse(decodedToken);
  }

  if (auth.token === null) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
